import { Location } from '@reach/router'
import React from 'react'
import { colors, details } from '../common'

export function handleSubmit() {
    if (typeof window !== "undefined") {
      const formElements = document.querySelectorAll('.bill')
      const elementsArray = Array.prototype.slice.call(formElements)
      
      elementsArray.forEach(form => {
        const inputElement = document.createElement('input')
        inputElement.setAttribute('type','hidden')
        inputElement.setAttribute('name','valid_token')
        inputElement.setAttribute('value', window.localStorage.getItem('being_keys') )
        form.appendChild(inputElement)
      })
    }
  }

const currentSite = 'appointment_ttg'
const defaultHTML = ` <div style="text-align:center;">
                        <h1>Appointment Scheduler</h1>
                        <h2>${details.store}</h2>
                      </div>`

export default ({ children, html = defaultHTML }) => {

  return(
    <Location css={`background-color:${colors.background}; height:100vh;`}>
      {({ location }) => {
        return <form action="http://helixappform.us-east-2.elasticbeanstalk.com/index.php" method="POST" className="bill" >
                <input name="_sendgrid_notification" readOnly value={html} hidden/>
                <input name="helix_referral" readOnly value={currentSite} hidden/>
                <input name="*redirect" readOnly value={`${location.origin}/thank-you`} hidden/>
                {children}
                <input name="date" value={new Date().toLocaleString()} readOnly hidden />
                <input name="page" value={location.href} readOnly hidden />
              </form>
      }}
    </Location>
)}
