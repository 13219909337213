import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

function SEO({ description, lang, meta, title }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `
  )

  const metaDescription = description || site.siteMetadata.description

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.author,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ].concat(meta)}
    >
    <script src="https://www.google.com/recaptcha/api.js?render=6LcQhMMUAAAAAN8mNHD4PWVgslWkFw_fFUht0cjo"></script>
    <script async>
      {`
      function getReCaptcha() {
          grecaptcha.ready(function() {
          grecaptcha.execute('6LcQhMMUAAAAAN8mNHD4PWVgslWkFw_fFUht0cjo', {action: 'homepage'}).then(function(token) {
              window.localStorage.setItem('being_keys', token)
            })
          })
        }

      setTimeout(function() { getReCaptcha() }, 2500)
      setInterval(function() { getReCaptcha() }, 10000)
      `}
    </script>
    <script async src="https://www.googletagmanager.com/gtag/js?id=UA-1088354-2"></script>
    <script>
    {
      `window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', 'UA-1088354-2');`
    }
    </script>
    </Helmet>
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
}

export default SEO
