import styled from 'styled-components'
import mobileBG from '../images/mobile_background.jpg'
import desktopBG from '../images/desktop_background.jpg'

export const colors = {
  primaryColor: '#004976',
  primaryContrast: 'white',
  background: '#fff'
}

export const details = {
  store: 'Technology Therapy Group',
  site: 'https://www.technologytherapy.com/?utm_source=scheduler',
}

export const md = `@media(max-width:768px)`

export const SectionWrap = styled.div`
  margin: auto;
  min-height: 100vh;
  width:100vw;
  overflow:scroll;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-image: url(${desktopBG ? desktopBG : ''});
  background-size:cover;
  background-position:center;
  position: relative;

  ${md} {
    background-image: url(${mobileBG ? mobileBG : ''});
    margin: 0;
    min-height: calc(100vh - 4rem);
  }
`

export const P = styled.p`
  color: ${colors.primaryContrast};
  background: ${colors.primaryColor};
  padding: 1.25rem;
  text-transform:uppercase;
  letter-spacing:.03rem;
  margin: 1rem auto;
  max-width: 800px;
  cursor:pointer;

  ${md} {
    min-width:100px;
  }
`

export const Wrap = styled.div`
  max-width:800px;
  margin:auto;

  ${md} {
    margin: 0 1rem;
  }
`

export const Title = styled.h2`
  margin-top: 6rem;

  ${md} {
    margin-top:4rem;
  }
`

export const Logo = styled.img`
  max-width: 200px;
  margin:0 auto;
  margin-top: 2rem;

  ${md} {
    max-width: 100px;
  }
`
