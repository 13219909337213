import React from 'react'

import FormWrap from '../components/formWrap'
import styled from 'styled-components'
import { colors } from '../common'
import './layout.css'

export default ({ children }) => {
  return (
    <FormWrap>
      <Wrap>
        <main>{children}</main>
      </Wrap>
    </FormWrap>
  )
}

const Wrap = styled.div`
  text-align:center;
  color:${colors.primaryColor};
`
